<template>
  <div v-if="hasPerm('payments.view_invoice')" class="entity-payment-synthesis">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName) && synthesis" class="field-line">
      <div class="info-text">
        Cette synthèse prend en compte les factures et règlements pour les saisons sélectionnées
        quelques soient les dates de facturation ou de paiement.
      </div>
      <table class="table table-bordered table-striped table-responsive">
        <tr>
          <td colspan="4" class="td-empty"></td>
          <td :colspan="synthesis.hasRewards() ? 3 : 2" :class="{ due: synthesis.dueTotalSinceStartDate > 0, }">
            À payer
          </td>
          <th>
            <div class="small-text3">Total des factures non payées <br />depuis {{ synthesis.startDate | dateToString('MMM YYYY') }}</div>
          </th>
          <td class="number" :class="{ due: synthesis.dueTotalSinceStartDate > 0, }">
            {{ synthesis.dueTotalSinceStartDate | currency }}
          </td>
        </tr>
        <tr>
          <th colspan="4">
            {{ synthesis.getSchoolYears() }}
          </th>
          <td :colspan="synthesis.hasRewards() ? 3 : 2" :class="{ due: synthesis.dueTotal() > 0, }">
            Montants dûs
          </td>
          <td :class="{ due: synthesis.dueTotal() > 0, }"></td>
          <td class="number" :class="{ due: synthesis.dueTotal() > 0, }">
            {{ synthesis.dueTotal() | currency }}
          </td>
        </tr>
        <tr v-if="synthesis.refundedTotal()">
          <td colspan="4" class="td-empty"></td>
          <td :colspan="synthesis.hasRewards() ? 4 : 3" :class="{ due: synthesis.refundedTotal() > 0, }">
            Remboursé
          </td>
          <td class="number" :class="{ due: synthesis.refundedTotal() > 0, }">
            {{ synthesis.refundedTotal() | currency }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="td-empty"></td>
          <td :colspan="synthesis.hasRewards() ? 4 : 3" :class="{ creditor: synthesis.remainingCredits > 0, }">
            Avoirs en cours
          </td>
          <td class="number" :class="{ creditor: synthesis.remainingCredits, }">
            {{ synthesis.remainingCredits | currency }}
          </td>
        </tr>
        <tr><td class="td-empty" :colspan="synthesis.hasRewards() ? 9 : 8"></td></tr>
        <tr v-if="synthesis.items.length">
          <th></th>
          <th colspan="3" class="text-center">Ventes</th>
          <th colspan="3" class="text-center">Payé</th>
          <th v-if="synthesis.hasRewards()"></th>
          <th></th>
        </tr>
        <tr v-if="synthesis.items.length">
          <th></th>
          <th>Facturé</th>
          <th>À facturer</th>
          <th>Annulations</th>
          <th>Remboursé</th>
          <th>Numéraire</th>
          <th>Par avoir</th>
          <th v-if="synthesis.hasRewards()">Chantiers</th>
          <th>Dû</th>
        </tr>
        <tr v-for="item of synthesis.items" :key="item.key()">
          <td class="elt-label">
            <span v-if="item.analyticAccount" :title="item.analyticAccount.name" v-b-tooltip>
              {{ item.analyticAccount.getLabel() }}
            </span>
            {{ item.schoolYear.name }}
          </td>
          <td class="number">{{ item.sold | currency }}</td>
          <td class="number">{{ item.invoicing | currency }}</td>
          <td class="number">{{ item.cancelled | currency }}</td>
          <td class="number">{{ item.refunded | currency }}</td>
          <td class="number">{{ item.paid | currency }}</td>
          <td class="number">{{ item.paidByCredit | currency }}</td>
          <td class="number" v-if="synthesis.hasRewards()">{{ item.rewards | currency }}</td>
          <td class="number">{{ item.due() | currency }}</td>
        </tr>
        <tr v-if="synthesis.sums">
          <th class="elt-label">Total</th>
          <th class="number">{{ synthesis.sums.sold | currency }}</th>
          <th class="number">{{ synthesis.sums.invoicing | currency }}</th>
          <th class="number">{{ synthesis.sums.cancelled | currency }}</th>
          <th class="number">{{ synthesis.sums.refunded | currency }}</th>
          <th class="number">{{ synthesis.sums.paid | currency }}</th>
          <th class="number">{{ synthesis.sums.paidByCredit | currency }}</th>
          <th class="number" v-if="synthesis.hasRewards()">{{ synthesis.sums.rewards | currency }}</th>
          <th class="number">{{ synthesis.sums.due() | currency }}</th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePaymentSynthesis } from '@/types/payments'

export default {
  name: 'entity-payment-synthesis',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
  },
  data() {
    return {
      loadingName: 'entity-payment-synthesis',
      synthesis: null,
      sum: null,
    }
  },
  computed: {
    label() {
      return this.invoice.getLabel()
    },
  },
  mounted() {
    this.loadSynthesis()
  },
  methods: {
    async loadSynthesis() {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.loadingName)
        let url = '/api/entity/' + this.entity.id + '/payment-synthesis/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.synthesis = makePaymentSynthesis(resp.data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.entity-payment-synthesis {
  font-size: 13px;
}
td, th {
  text-align: center;
}
th {
  background: #444;
  color: #fff;
}
th.elt-label, td.elt-label {
  text-align: left;
}
.number {
  text-align: right;
}
th.due, td.due {
  background: #f8b200 !important;
}
th.creditor, td.creditor {
  background: #9dec9d !important;
}
td.td-empty {
  background: #fff !important;
}
.small-text3 {
  font-size: 9px;
}
</style>
